<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4">
          <!-- md="8" -->
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Đăng nhập</h1>
                  <p class="text-muted">Đăng nhập vào tài khoản KLC của bạn</p>
                  <CInput
                    placeholder="Email"
                    autocomplete="email"
                    v-model="model.email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="model.password"
                    @keyup.enter="login"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CSpinner
                        v-if="loading"
                        color="info"
                        size="sm"
                        class="ml-2"
                      />
                      <CButton
                        v-else
                        color="primary"
                        class="px-4"
                        @click="login"
                      >
                        Đăng nhập
                      </CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton
                        color="link"
                        class="px-0"
                        @click="forgotPassword"
                      >
                        Quên mật khẩu
                      </CButton>
                      <CButton v-if="false" color="link" class="d-lg-none">
                        Register now!
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              v-if="false"
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <CButton color="light" variant="outline" size="lg">
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      model: {
        email: "",
        password: "",
        rememberMe: false,
      },
      loading: false,
    };
  },
  computed: {
    returnUrl() {
      return this.$route.query.returnUrl;
    },
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        let email = this.model.email;
        let password = this.model.password;
        let resp = await this.$user.dispatch("login", {
          UserName: email,
          Password: password,
        });

        if (resp.data.status == this.$const.RESPONSE_TYPES.Success) {
          //this.$router.push(this.returnUrl ? this.returnUrl : "/");
          let href = this.returnUrl ? this.returnUrl : "/";
          location.href = href;
        } else if (resp.data.message) {
          alert(this.$const.MESSAGE[resp.data.message]);
        }
      } catch (error) {
        alert(error);
      }
      this.loading = false;
    },
    forgotPassword() {
      alert(
        "Vui lòng liên hệ quản trị viên hệ thống để được cài đặt lại mật khẩu!"
      );
    },
  },
};
</script>
